"use client";

import { FC, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import { ComponentPageHomeSectionAbout } from "@Shared/api";
import { PAGES } from "@Shared/config";
import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { Button, ContainerSection, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { Cursors } from "./Cursors";
import imageBgDevices from "./images/bg-d.webp";
import imageBg from "./images/bg.webp";
import { Lines } from "./Lines";

export type SectionAboutProps = ElProps<"section"> & ComponentPageHomeSectionAbout;

export const SectionAbout: FC<SectionAboutProps> = ({ className, title, buttonAboutText, cursors, ...restProps }) => {
    const refComponent = useRef<HTMLDivElement>(null);
    const refContainer = useRef<HTMLDivElement>(null);
    const refLines = useRef<HTMLDivElement>(null);
    const refCursors = useRef<HTMLDivElement>(null);
    const refTitle = useRef<HTMLHeadingElement>(null);
    const refButton = useRef<HTMLDivElement>(null);

    const { width } = useWindowSize();
    const { isSm } = useScreens();

    useGSAP(
        () => {
            if (!refComponent.current || !refContainer.current || !refLines.current || !refTitle.current) {
                return;
            }

            const $sectionBefore = refComponent.current.previousElementSibling;
            const $cursors = refCursors.current?.querySelectorAll(".about-cursor") as NodeListOf<HTMLDivElement>;

            const animationDistance = 600 + ($cursors?.length ? 400 : 0) + (refButton.current ? 200 : 0);
            const containerProps = {
                width: refContainer.current.offsetWidth,
                height: refContainer.current.offsetHeight,
                halfWidth: refContainer.current.offsetWidth / 2,
                halfHeight: refContainer.current.offsetHeight / 2,
            };

            const componentOffsetTop = $sectionBefore
                ? refComponent.current.getBoundingClientRect().top - $sectionBefore.getBoundingClientRect().bottom
                : 0;

            const containerBrs = parseInt(getComputedStyle(refContainer.current).borderRadius, 10);
            const clipBrs = isNaN(containerBrs) ? 0 : containerBrs;
            const cpInset = {
                x: 0,
                y: 0,
                brs: clipBrs,
            };

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: refComponent.current,
                    start: `top top+=${componentOffsetTop}`,
                    end: `+=${animationDistance}`,
                    scrub: 1,
                },
            });

            gsap.set(refComponent.current, { clearProps: "minHeight" });

            gsap.set(refComponent.current, {
                minHeight: `${refComponent.current.clientHeight + animationDistance}px`,
            });

            gsap.set(refContainer.current, {
                "--cp-inset-x": cpInset.x,
                "--cp-inset-y": cpInset.y,
                "--cp-inset-brs": cpInset.brs,
                position: "sticky",
                top: componentOffsetTop,
                clipPath:
                    "inset(calc(var(--cp-inset-y) * 1px) calc(var(--cp-inset-x) * 1px) round calc(var(--cp-inset-brs) * 1px))",
            });

            tl.from(refContainer.current, {
                duration: 1,
                ease: "none",
                "--cp-inset-y": containerProps.halfHeight - 0.5,
            })
                .from(
                    refLines.current,
                    {
                        duration: 0.75,
                        ease: "none",
                        yPercent: -100,
                        scale: 1.25,
                    },
                    "-=25%"
                )
                .from(
                    refTitle.current,
                    {
                        ease: "none",
                        y: 75,
                        opacity: 0,
                    },
                    "-=50%"
                );

            if (refButton.current) {
                tl.from(
                    refButton.current,
                    {
                        ease: "none",
                        duration: 0.25,
                        y: 15,
                        opacity: 0,
                    },
                    "-=50%"
                );
            }

            if ($cursors?.length) {
                const cursorsAdditionalOffset = isSm ? 150 : 75;

                gsap.set($cursors, { clearProps: "transform" });

                $cursors.forEach(($el, index) => {
                    let x = 0;
                    let y = 0;

                    if ($el.offsetLeft < containerProps.halfWidth) {
                        x = -$el.offsetLeft - $el.offsetWidth - cursorsAdditionalOffset;
                    } else {
                        x = containerProps.width - $el.offsetLeft + cursorsAdditionalOffset;
                    }

                    if ($el.offsetTop < containerProps.halfHeight) {
                        y = -$el.offsetTop - $el.offsetHeight - cursorsAdditionalOffset;
                    } else {
                        y = containerProps.height - $el.offsetTop + cursorsAdditionalOffset;
                    }

                    tl.from(
                        $el,
                        {
                            duration: 1,
                            ease: "sine.out",
                            x,
                            y,
                        },
                        index ? "<" : undefined
                    );
                });
            }

            tl.from(refComponent.current, { ease: "none" });
        },
        {
            scope: refComponent,
            revertOnUpdate: true,
            dependencies: [width, isSm],
        }
    );

    return (
        <section ref={refComponent} className="scroll-m-t-header relative w-full" id="about">
            <ContainerSection
                ref={refContainer}
                className={twcx(
                    "min-h-svh-section flex flex-col items-center justify-center gap-4 py-48",
                    "lg:gap-8",
                    className
                )}
                bgImageCover={{ src: imageBg, srcDevices: imageBgDevices, sizes: { default: "99vw" } }}
                {...restProps}
            >
                <Lines ref={refLines} />
                <Cursors ref={refCursors} list={cursors} />

                <Text ref={refTitle} className="lg:max-w-[1140px]" as="h2" variant="h3" weight={600} align="center">
                    {title}
                </Text>

                {buttonAboutText && (
                    <div ref={refButton}>
                        <Button variant="underline" icon="angleRight" href={PAGES.about}>
                            {buttonAboutText}
                        </Button>
                    </div>
                )}
            </ContainerSection>
        </section>
    );
};
